import "../css/styles.css";
import { throttle } from "throttle-debounce";

function invokeOnScroll() {
	if (window.scrollInfoService != null) {
		window.scrollInfoService.invokeMethodAsync("OnScroll", window.pageYOffset);
	}
}

const invokeOnScrollThrottled = throttle(100, () => invokeOnScroll(), { noLeading: true });

window.onscroll = function () {
	invokeOnScrollThrottled();
};

window.RegisterScrollInfoService = (scrollInfoService) => {
	window.scrollInfoService = scrollInfoService;
};

window.getInnerWidth = function() {
	return window.innerWidth;
};

window.getInnerHeight = function() {
	return window.innerHeight;
};

window.focusElement = function(id) {
	document.getElementById(id)?.focus();
};

window.lockScroll = function(locked) {
	document.body.style.overflow = locked ? 'hidden' : 'visible';
};
